export default {
  speed: {
    name: 'speed',
    linesCount: 1,
    linesColour: '#00b3ff',
    addLineNames: false,
    hints: {
      title: 'analysis.distanceHintsTitle',
      texts: ['analysis.distanceHint1', 'analysis.distanceHint2', 'analysis.distanceHint3'],
    },
  },
  traffic: {
    name: 'traffic',
    linesCount: 100,
    linesColour: '#ff0000',
    addLineNames: true,
    hints: {
      title: 'analysis.countingLinesHintsTitle',
      texts: [
        'analysis.countingLinesHint1',
        'analysis.countingLinesHint2',
        'analysis.countingLinesHint3',
        'analysis.countingLinesHint4',
      ],
    },
  },
};
