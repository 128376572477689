//@flow
import React from 'react';

const makeLoadable = React.lazy;

export const LoadableMapScreen = makeLoadable(() => import('modules/map/components/MapScreen'));

export const LoadableMeasurementScreen = makeLoadable(
  () => import('modules/measurements/components/MeasurementScreen')
);

export const LoadableEditUserScreen = makeLoadable(
  () => import('modules/auth/components/EditUserScreen')
);

export const LoadableLoginScreen = makeLoadable(
  () => import('modules/auth/components/LoginScreen')
);

export const LoadableOidcLoginScreen = makeLoadable(
  () => import('modules/auth/components/OidcLoginScreen')
);

export const LoadableCreateMeasurementScreen = makeLoadable(
  () => import('modules/measurements/components/CreateMeasurementScreen')
);

export const LoadableCreateCompleteMeasurementScreen = makeLoadable(
  () => import('modules/measurements/components/CreateCompleteMeasurementScreen')
);

export const LoadableEditMeasurementScreen = makeLoadable(
  () => import('modules/measurements/components/EditMeasurementScreen')
);

export const LoadableEditMeasuringSiteScreen = makeLoadable(
  () => import('modules/measurements/components/EditMeasuringSiteScreen')
);

export const LoadableCreateMeasuringSiteScreen = makeLoadable(
  () => import('modules/measurements/components/CreateMeasuringSiteScreen')
);

export const LoadableResetPasswordScreen = makeLoadable(
  () => import('modules/auth/components/ResetPasswordScreen')
);

export const LoadableRegistrationScreen = React.lazy(
  () => import('modules/auth/components/RegistrationScreen/RegistrationScreen')
);

export const LoadableRegistrationSuccessScreen = React.lazy(
  () => import('modules/auth/components/RegistrationScreen/RegistrationSuccessScreen')
);

export const LoadableCompareMeasurementsScreen = makeLoadable(
  () => import('modules/measurements/components/CompareMeasurementsScreen')
);

export const LoadableOrganizationListScreen = makeLoadable(
  () => import('modules/customers/components/OrganizationListScreen')
);

export const LoadableCreateOrganizationScreen = makeLoadable(
  () => import('modules/customers/components/CreateOrganizationScreen')
);

export const LoadableEditOrganizationScreen = makeLoadable(
  () => import('modules/customers/components/EditOrganizationScreen')
);

export const LoadableCreateOrganizationAccountScreen = makeLoadable(
  () => import('modules/customers/components/CreateOrganizationAccountScreen')
);

export const LoadableEditOrganizationAccountScreen = makeLoadable(
  () => import('modules/customers/components/EditOrganizationAccountScreen')
);

export const LoadableMeasuringInstrumentListScreen = makeLoadable(
  () => import('modules/measurements/components/MeasuringInstrumentListScreen')
);

export const LoadableViewMeasuringInstrumentConfigScreen = makeLoadable(
  () => import('modules/measurements/components/ViewMeasuringInstrumentConfigScreen')
);

export const LoadableCreateMeasuringInstrumentConfigScreen = makeLoadable(
  () => import('modules/measurements/components/CreateMeasuringInstrumentConfigScreen')
);

export const LoadableAppLayout = makeLoadable(() => import('modules/app/components/AppLayout'));

export const LoadableChangePasswordScreen = makeLoadable(
  () => import('modules/auth/components/ChangePasswordScreen')
);

export const LoadableSetPasswordScreen = makeLoadable(
  () => import('modules/auth/components/SetPasswordScreen')
);

export const LoadableActivateUserScreen = makeLoadable(
  () => import('modules/auth/components/ActivateUserScreen')
);

export const LoadableCreateReportScreen = makeLoadable(
  () => import('modules/measurements/components/CreateReportScreen')
);

export const LoadableCreateMeasuringInstrumentScreen = makeLoadable(
  () => import('modules/measurements/components/CreateMeasuringInstrumentScreen')
);

export const LoadableEditMeasuringInstrumentScreen = makeLoadable(
  () => import('modules/measurements/components/EditMeasuringInstrumentScreen')
);

export const LoadableArgosReleasesListScreen = makeLoadable(
  () => import('modules/measurements/components/ArgosReleasesListScreen')
);

export const LoadableNotFound = makeLoadable(() => import('modules/app/components/NotFound'));

export const LoadablePublicAppLayout = makeLoadable(
  () => import('modules/app/components/PublicAppLayout')
);

export const LoadableTasksListScreen = makeLoadable(
  () => import('modules/tasks/components/TasksListScreen')
);

export const LoadableBackendSystemInfoScreen = makeLoadable(
  () => import('modules/app/components/BackendSystemInfoScreen/BackendSystemInfoScreen')
);
export const LoadablePostpaidBillingScreen = makeLoadable(
  () => import('modules/billing/components/BillingScreen')
);
