//@flow
import { apiClient } from '../../store';
import type { CrudAction } from './typeDefs';

export const apiCaller = (action) => {
  const { fakeCall, payload } = action;

  if (fakeCall) return Promise.resolve({});

  const { method, path, params, data, fetchConfig } = payload;

  if (!method) {
    return Promise.reject(new Error('API Method is undefined'));
  }

  return apiClient[method](path, { params, data, fetchConfig });
};
