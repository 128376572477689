//@flow
import { jwtDecode } from 'jwt-decode';
import { ROLE_DISTRIBUTOR } from 'lib/constants';
import * as loadables from 'lib/loadables';
import storageService from 'lib/storageService';
import qs from 'qs';
import React, { Suspense, useContext, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import type { JwtToken } from 'typedefs';
import { useLastLocation } from './lastLocationContext';
import { JwtTokenDecoded } from 'typedefs';

export const DecodedJwtTokenContext = React.createContext({});
export const useDecodedJwtToken = (): JwtTokenDecoded => {
  const ctx: JwtToken = useContext(DecodedJwtTokenContext);
  return {
    userId: ctx.uid,
    organizationId: ctx.oid,
    isDistributor: ctx.role === ROLE_DISTRIBUTOR,
    isSuperuser: ctx.su,
    isOrganizationAdmin: ctx.uid === ctx.oadmin,
    timezone: ctx.tz,
  };
};

const ModalSwitch = () => {
  const token = storageService.get('token');
  const tokenDecoded = useMemo(() => (token ? jwtDecode(token) : null), [token]);
  const isAuthenticated = !!tokenDecoded;

  const location = useLocation();
  const lastLocation = useLastLocation();
  const isModal = !!(location.state && location.state.modal);
  const shouldGoToHome = isAuthenticated && location.pathname === '/auth/login';

  return (
    <DecodedJwtTokenContext.Provider value={tokenDecoded}>
      <Suspense fallback={<Loader active size="large" />}>
        <Routes location={isModal ? lastLocation : location}>
          {shouldGoToHome && <Route path="*" element={<Navigate to="/" />} />}
          <Route path="/auth" element={<loadables.LoadablePublicAppLayout />}>
            <Route
              path="login/oidc/:oidc_provider_id/callback"
              element={<loadables.LoadableOidcLoginScreen />}
            />
            <Route path="login" element={<loadables.LoadableLoginScreen />} />
            <Route path="resetPassword" element={<loadables.LoadableResetPasswordScreen />} />
            <Route path="register" element={<loadables.LoadableRegistrationScreen />} />
            <Route
              path="register/success"
              element={<loadables.LoadableRegistrationSuccessScreen />}
            />
            <Route
              path="setPassword/:uid/:token"
              element={<loadables.LoadableSetPasswordScreen />}
            />
            <Route path="confirm/:uid/:token" element={<loadables.LoadableActivateUserScreen />} />
          </Route>
          {!isAuthenticated && <Route path="*" element={<Navigate to="/auth/login" />} />}
          {isAuthenticated && (
            <Route path="/" element={<loadables.LoadableAppLayout />}>
              <Route
                path="measurements/new"
                element={
                  <loadables.LoadableCreateMeasurementScreen
                    {...qs.parse(location.search, { ignoreQueryPrefix: true })}
                  />
                }
              />
              <Route
                path="measurements/:instrumentType/:id"
                element={<loadables.LoadableMeasurementScreen />}
              />
              <Route
                path="measurements/:instrumentType/:id/complete"
                element={<loadables.LoadableCreateCompleteMeasurementScreen />}
              />
              <Route
                path="measurements/:instrumentType/:id/edit"
                element={
                  <loadables.LoadableEditMeasurementScreen
                    {...qs.parse(location.search, { ignoreQueryPrefix: true })}
                  />
                }
              />
              <Route
                path="measurements/:instrumentType/:id/create-report"
                element={<loadables.LoadableCreateReportScreen />}
              />
              <Route
                path="compare-measurements/:instrumentType/:id1/:id2"
                element={<loadables.LoadableCompareMeasurementsScreen />}
              />
              <Route
                path="measuring-sites/new"
                element={<loadables.LoadableCreateMeasuringSiteScreen />}
              />
              <Route
                path="measuring-sites/:id"
                element={<loadables.LoadableEditMeasuringSiteScreen />}
              />
              <Route
                path="measuring-instruments/"
                element={<loadables.LoadableMeasuringInstrumentListScreen />}
              />
              <Route
                path="measuring-instruments/new"
                element={<loadables.LoadableCreateMeasuringInstrumentScreen />}
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial"
                element={
                  <loadables.LoadableEditMeasuringInstrumentScreen area="measuringInstrument" />
                }
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/configurations"
                element={<loadables.LoadableEditMeasuringInstrumentScreen area="configurations" />}
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/configurations/new"
                element={<loadables.LoadableCreateMeasuringInstrumentConfigScreen />}
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/configurations/:id"
                element={<loadables.LoadableViewMeasuringInstrumentConfigScreen />}
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/patches"
                element={<loadables.LoadableEditMeasuringInstrumentScreen area="patches" />}
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/updates"
                element={<loadables.LoadableEditMeasuringInstrumentScreen area="updates" />}
              />
              <Route
                path="customers"
                element={<loadables.LoadableOrganizationListScreen model="customer" />}
              />
              <Route
                path="customers/new"
                element={<loadables.LoadableCreateOrganizationScreen model="customer" />}
              />
              <Route
                path="customers/edit/:id"
                element={
                  <loadables.LoadableEditOrganizationScreen area="organization" model="customer" />
                }
              />
              <Route
                path="customers/edit/:id/accounts"
                element={
                  <loadables.LoadableEditOrganizationScreen area="accounts" model="customer" />
                }
              />
              <Route
                path="customers/edit/:id/billing"
                element={
                  <loadables.LoadableEditOrganizationScreen area="billing" model="customer" />
                }
              />
              <Route
                path="customers/edit/:organizationId/accounts/edit/:id"
                element={
                  <loadables.LoadableEditOrganizationAccountScreen organizationModel="customer" />
                }
              />
              <Route
                path="customers/edit/:organizationId/accounts/new"
                element={
                  <loadables.LoadableCreateOrganizationAccountScreen
                    organizationModel="customer"
                    {...qs.parse(location.search, { ignoreQueryPrefix: true })}
                  />
                }
              />
              <Route
                path="distributors"
                element={<loadables.LoadableOrganizationListScreen model="distributor" />}
              />
              <Route
                path="distributors/new"
                element={<loadables.LoadableCreateOrganizationScreen model="distributor" />}
              />
              <Route
                path="distributors/edit/:id"
                element={
                  <loadables.LoadableEditOrganizationScreen
                    area="organization"
                    model="distributor"
                  />
                }
              />
              <Route
                path="distributors/edit/:id/accounts"
                element={
                  <loadables.LoadableEditOrganizationScreen area="accounts" model="distributor" />
                }
              />
              <Route
                path="distributors/edit/:id/billing"
                element={
                  <loadables.LoadableEditOrganizationScreen area="billing" model="distributor" />
                }
              />
              <Route
                path="distributors/edit/:organizationId/accounts/edit/:id"
                element={
                  <loadables.LoadableEditOrganizationAccountScreen organizationModel="distributor" />
                }
              />
              <Route
                path="distributors/edit/:organizationId/accounts/new"
                element={
                  <loadables.LoadableCreateOrganizationAccountScreen organizationModel="distributor" />
                }
              />
              <Route path="user/:id" element={<loadables.LoadableEditUserScreen />} />
              <Route
                path="user/:id/password"
                element={<loadables.LoadableChangePasswordScreen />}
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/versions"
                element={<loadables.LoadableEditMeasuringInstrumentScreen area="versions" />}
              />
              <Route
                path="measuring-instruments/releases/argos/"
                element={
                  <loadables.LoadableArgosReleasesListScreen
                    {...qs.parse(location.search, { ignoreQueryPrefix: true })}
                  />
                }
              />
              <Route path="/" element={<loadables.LoadableMapScreen />} />
              <Route path="tasks" element={<loadables.LoadableTasksListScreen />} />
              <Route path="system-info" element={<loadables.LoadableBackendSystemInfoScreen />} />
              <Route
                path="postpaid-billing"
                element={<loadables.LoadablePostpaidBillingScreen />}
              />
              {!(isModal && !lastLocation) && (
                <Route path="*" element={<loadables.LoadableNotFound />} />
              )}
            </Route>
          )}
          <Route path="*" element={<loadables.LoadableNotFound />} />
        </Routes>
      </Suspense>
    </DecodedJwtTokenContext.Provider>
  );
};

export default ModalSwitch;
