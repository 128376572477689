//@flow
import omit from 'lodash/omit';
import * as actionTypes from 'modules/auth/actionTypes';
import type {
    AccountRegistration,
    Configuration,
    LogoutAction,
    SetAccessTokenAction,
    SetTokenPairAction,
    User,
} from 'modules/auth/typedefs';
import type { JwtToken } from 'typedefs';
import type { CrudAction } from 'lib/comms_v2/typeDefs';
import {
  createRecord,
  deleteRecord,
  fetchCollection,
  fetchRecord,
  updateRecord,
} from 'lib/comms_v2/actionCreators';

export const login = (email: string, password: string): CrudAction<JwtToken> =>
  createRecord('token', '/accounts/token/', { email, password });

export const loginAs = (user: string): CrudAction<JwtToken> =>
  createRecord('token', '/accounts/token/for-user/', { user });

export const loginOidc = (oidcProviderId: string, redirectUri: string): CrudAction<JwtToken> =>
  createRecord('token', `/oidc/${oidcProviderId}/callback/`, { redirectUri });

export const logout = (): LogoutAction => ({
  type: actionTypes.LOGOUT,
  payload: {},
});

export const createRegistration = (values: AccountRegistration): CrudAction<AccountRegistration> =>
  createRecord('AccountRegistration', '/accounts/users/submit-registration/', values);

export const setAccessToken = (accessToken: string): SetAccessTokenAction => ({
  type: actionTypes.SET_ACCESS_TOKEN,
  payload: { accessToken },
});

export const setTokenPair = (accessToken: string, refreshToken: string): SetTokenPairAction => ({
  type: actionTypes.SET_TOKEN_PAIR,
  payload: { accessToken, refreshToken },
});

export const getUser = (id: number, organizationId?: number): CrudAction<User> => {
  return fetchRecord(
    'user',
    id,
    `/accounts/users/${id}/?organization=${organizationId ? organizationId : ''}`
  );
};

export const getUsers = (organizationId?: number): CrudAction<User> => {
  return fetchCollection('user', `/accounts/users/`, { organization: organizationId });
};

export const updateUser = (id: number, values: any): CrudAction<User> => {
  return updateRecord(
    'user',
    id,
    `/accounts/users/${id}/`,
    values,
    {},
    {
      method: 'PATCH',
    }
  );
};

export const createUser = (values: any): CrudAction<User> => {
  return createRecord('user', '/accounts/users/', values);
};

export const sendInvitation = (userId: number): CrudAction<User> => {
  return updateRecord(
    'user',
    userId,
    `/accounts/users/${userId}/send-invitation/`,
    {},
    {},
    {
      method: 'PUT',
    }
  );
};

export const getInactiveUser = (hash: string, token: string): CrudAction<User> => {
  return fetchRecord('inactiveUser', hash, `/accounts/users/confirm/${hash}/${token}/`);
};

export const confirmUser = (hash: string, token: string, values: *): CrudAction<User> => {
  return createRecord('inactiveUser', `/accounts/users/confirm/${hash}/${token}/`, values);
};

export const deleteUser = (userId: number): CrudAction<User> => {
  return deleteRecord('user', userId, `/accounts/users/${userId}/`);
};

export const changePassword = (userid: number, password: string): CrudAction<*> =>
  createRecord(
    'changePassword',
    `/accounts/users/${userid}/set-password/`,
    { password },
    {},
    {
      fetchConfig: { format: 'text' },
    }
  );

export const resetPassword = (uid: string, token: string, password: string): CrudAction<*> =>
  createRecord(
    'resetPassword',
    `/accounts/users/reset-password/${uid}/${token}/`,
    { password },
    {},
    {
      fetchConfig: { format: 'text' },
    }
  );

export const sendResetPassword = (email: string): CrudAction<*> =>
  createRecord(
    'sendResetPassword',
    '/accounts/users/send-password-reset/',
    { email },
    {},
    {
      fetchConfig: { format: 'text' },
      method: 'PUT',
    }
  );

export const getConfiguration = (): CrudAction<Configuration> => {
  return fetchRecord('configuration', null, '/configurations/configuration/');
};

export const updateConfiguration = (configuration: Configuration): CrudAction<Configuration> => {
  return updateRecord(
    'configuration',
    null,
    `/configurations/configuration/`,
    omit(configuration, ['url', 'user']),
    {},
    {
      method: 'PATCH',
    }
  );
};
